/*
 * @Author: jianying.wan
 * @Date: 2019-12-12 09:22:00
 * @LastEditors  : jianying.wan
 * @LastEditTime : 2019-12-24 11:03:40
 * @Description: 停车优惠券
 */
import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '已过期',
    value: 2
  },
  {
    text: '已关闭',
    value: 1
  }
]

// 优惠券类型  时间减免暂不实现
const type = [
  {
    text: '一次全免',
    value: 2
  },
  {
    text: '金额减免',
    value: 0
  }
  // {
  //   text: '时间减免',
  //   value: 1
  // }
]

// 过期未使用是否返还
const returnTypeOps = [
  {
    text: '不返还',
    value: 0
  },
  {
    text: '返还',
    value: 1
  }
]

// 状态
const formEditStatusOps = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '已关闭',
    value: 1
  }
]

// 状态  0:待使用,1: 已使用,2：已过期,3:支付占用中,4:过期返还'
const couponUsestatus = [
  {
    text: '待使用',
    value: 0
  },
  {
    text: '已使用',
    value: 1
  },
  {
    text: '已过期',
    value: 2
  },
  {
    text: '过期返还',
    value: 4
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

const {
  map: typeMap,
  setOps: setTypeOps
} = mapHelper.setMap(type)

const {
  map: couponUseStatusMap,
  setOps: setCouponUseStatusops
} = mapHelper.setMap(couponUsestatus)
// 房屋类型
const houseTypeMap = {
  1: '公寓',
  2: '排屋',
  3: '别墅',
  4: '商铺',
  5: '办公用房',
  6: '经营用房',
  21: '保姆房',
  22: '储藏室',
  23: '自行车库',
  24: '车库',
  25: '车位',
  26: '其他',
  27: '地下室'
}
// 房屋状态
const roomStatus = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '空置',
    value: 1
  }, {
    text: '未领',
    value: 2
  }, {
    text: '空关',
    value: 3
  }, {
    text: '装修',
    value: 4
  }, {
    text: '入住',
    value: 5
  }, {
    text: '入伙',
    value: 6
  }
]

const {
  map: roomStatusMap,
  setOps: roomStatusOps
} = mapHelper.setMap(roomStatus)

export {
  statusMap,
  setStatusOps,
  typeMap,
  setTypeOps,
  returnTypeOps,
  formEditStatusOps,
  couponUseStatusMap,
  setCouponUseStatusops,
  houseTypeMap,
  roomStatusMap,
  roomStatusOps
}
