<!--
 * @Author: jianying.wan
 * @Date: 2019-12-12 09:23:00
 * @LastEditors  : jianying.wan
 * @LastEditTime : 2019-12-31 14:18:57
 * @Description: 停车优惠券
 -->
<template>
  <div class="ReleaseRecordList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :hasOperateColumn="false"
          :headers="headers"
          @searchSuccess="searchSuccess">
      <div class="statistics-wrapper">
        <div class="content">
          <span>已发放优惠券数</span>
          <span>{{ stats.couponNum }}</span>
        </div>
        <div class="content">
          <span>待使用/已使用/已过期/过期返还数</span>
          <span>{{ `${stats.unUsedNum}/${stats.usedNum}/${stats.expiredNum}/${stats.expiredRecyclingNum}` }}</span>
        </div>
        <div class="content">
          <span>累计减免金额（元）</span>
          <span>{{ stats.totalPrice }}</span>
        </div>
        <div class="content">
          <span>本月减免金额（元）</span>
          <span>{{ stats.monthTotalPrice }}</span>
        </div>
        <span class="margin-line"></span>
      </div>
      <template #headerSlot>
        <v-button v-if="needBackBtn"
                  text="返回"
                  @click="previous"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="优惠券编号"
                 v-model="searchParams.couponNumber"></v-input>
        <v-input label="优惠券名称"
                 v-model="searchParams.couponName"></v-input>
        <v-select label="优惠券类型"
                  v-model="searchParams.couponType"
                  :options="typeOps"></v-select>
        <v-select2 label="券池编号"
                   v-model="searchParams.couponPoolNumber"
                   v-bind="couponParams" />
        <v-input label="车牌号"
                 v-model="searchParams.carNum"></v-input>
        <v-select label="使用状态"
                  v-model="searchParams.couponStatus"
                  :options="statusOps"></v-select>
        <!--<v-select2 label="所属组织"-->
        <!--           v-model="searchParams.orgId"-->
        <!--           v-bind="orgParams" />-->
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams" />
        <v-select2 label="停车场"
                   v-model="searchParams.carParkId"
                   v-bind="parkingParams"
                   :disabled="!(searchParams.communityId && searchParams.communityId.length)"
                   :subjoin="parkSubjoin"></v-select2>
        <v-select2 label="所属房号" :disabled="!(searchParams.communityId && searchParams.communityId.length)" v-model="searchParams.houseId" placeholder="查询房号" v-bind="roomIdConfig" :subjoin="communityIdParams"></v-select2>
        <v-datepicker label="使用时间"
                      :startTime.sync="searchParams.usedTimeStart"
                      :endTime.sync="searchParams.usedTimeEnd"></v-datepicker>
        <v-datepicker label="发放时间"
                      :startTime.sync="searchParams.intimeStart"
                      :endTime.sync="searchParams.intimeEnd"></v-datepicker>
      </template>
    </list>
  </div>
</template>

<script>
import { orgParams, communityParams } from 'common/select2Params'
import { releaseRecordListURL, exportCouponReleaseURL, couponPoolSelect2URL,
  parkingSelect2URL, releaseRecordStatsURL, select2RoomIdUrl } from './api'
import { setCouponUseStatusops,
  setTypeOps } from './map'
import { mapGetters } from 'vuex'
export default {
  name: 'ReleaseRecordList',
  data () {
    return {
      searchUrl: releaseRecordListURL,
      exportUrl: exportCouponReleaseURL,
      communityParams,
      orgParams,
      couponParams: {
        searchUrl: couponPoolSelect2URL,
        request: {
          text: 'couponNumber',
          value: 'couponNumber'
        },
        response: {
          text: 'couponPoolNumber',
          value: 'couponPoolNumber'
        }
      },
      parkingParams: {
        searchUrl: parkingSelect2URL,
        request: {
          text: 'carParkName',
          value: 'carParkId'
        },
        response: {
          text: 'parkingName'
        }
      },
      statusOps: setCouponUseStatusops(1),
      typeOps: setTypeOps(1),
      searchParams: {
        couponNumber: '',
        couponName: '',
        couponPoolNumber: '',
        couponType: undefined,
        carNum: '',
        couponStatus: undefined,
        orgId: '',
        communityId: '',
        usedTimeStart: undefined,
        usedTimeEnd: undefined,
        intimeStart: undefined,
        intimeEnd: undefined,
        carParkId: ''
      },
      stats: {
        couponNum: 0,
        expiredNum: 0,
        expiredRecyclingNum: 0,
        monthTotalPrice: 0,
        totalPrice: 0,
        unUsedNum: 0,
        usedNum: 0
      },
      headers: [
        {
          prop: 'couponNumber',
          label: '优惠券编号'
        },
        {
          prop: 'couponPoolNumber',
          label: '券池编号'
        },
        {
          prop: 'couponName',
          label: '优惠券名称'
        },
        {
          prop: 'carNum',
          label: '车牌号'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'carParkName',
          label: '停车场'
        },
        {
          prop: 'houseNum',
          label: '所属房号'
        },
        {
          prop: 'couponType',
          label: '优惠券类型'
        },
        {
          prop: 'price',
          label: '减免金额（元）'
        },
        {
          prop: 'couponDuration',
          label: '减免时长'
        },
        {
          prop: 'intime',
          label: '发放时间'
        },
        {
          prop: 'expirationHour',
          label: '使用有效时间（天）'
        },
        {
          prop: 'couponStatus',
          label: '使用状态'
        },
        {
          prop: 'usedTime',
          label: '使用时间'
        }
      ],
        roomIdConfig: {
            searchUrl: select2RoomIdUrl,
            request: {
                text: 'address',
                value: 'id'
            },
            response: {

            }
        },
    }
  },
  computed: {
    parkSubjoin () {
      return { communityId: this.searchParams.communityId }
    },
    needBackBtn () {
      return !!this.$route.query.couponPoolNumber
    },
    ...mapGetters('searchStore', [
      'getListSearch'
    ])
  },
  mounted () {
    let couponPoolNumber = this.$route.query.couponPoolNumber
    if (couponPoolNumber && couponPoolNumber.length) {
      this.clearSearchParams()
      this.searchParams.couponPoolNumber = couponPoolNumber
    }
  },
  methods: {
    clearSearchParams () {
      // 清空搜索参数
      let self = this
      Object.keys(self.searchParams).forEach((key) => {
        self.searchParams[key] = undefined
      })
    },
    fetchStatsData () {
      let self = this
      let postData = {
        params: this.searchParams
      }
      this.$axios.get(releaseRecordStatsURL, postData)
        .then(res => {
          if (String(res.status) === '100') {
            let data = res.data
            Object.keys(self.stats).forEach((key) => {
              self.stats[key] = data[key]
            })
          }
        })
    },
    searchSuccess () {
      this.fetchStatsData()
    },
    previous () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.ReleaseRecordList-wrapper {
  .statistics-wrapper {
    width: 100%;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    .content {
      flex: 0 0 25%;
      height: 50px;
      span {
        display: block;
        text-align: center;
        margin-bottom: 16px;
      }
    }
    .margin-line {
      flex: 0 0 100%;
      height: 1px;
      width: 100%;
      background-color: #ebeef5;
    }
  }
}
</style>
